@import '../../../styles/customMediaQueries.css';

.label,
.labelSelected {
  display: inline-block;
  width: auto;
  height: 35px;
  min-height: 35px;

  padding: 0 18px;
  margin: 0 9px 0 0;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--boxShadowFilterButton);
    text-decoration: none;
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    height: auto;
    min-height: 0;
    padding: var(--marketplaceButtonSmallDesktopPadding);
    margin: 0;

    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 20px;
  }
}

.label, .labelSelected {
  border: none;
  /* Button borders */
  border-radius: var(--borderRadiusMedium);
  box-shadow: var(--boxShadowLight);

  background-color: var(--colorWhite);

  &:hover,
  &:focus {
    border-color: var(--colorGrey300);
  }
  &:disabled {
    border-color: var(--colorGrey100);
  }

  @media (--viewportMedium) {
    border: 1px solid var(--colorGrey100);
    box-shadow: none;
    &:hover,
    &:focus {
      border-color: transparent;
    }
  }
  @media only screen and (max-width: 550px){
    
  padding: 0;
  width: 43px;
  font-size: 21px;
    color: var(--colorGrey);
    text-align: center;
  }
}
 

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
