@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;

  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    position: relative;
    padding-top: calc(var(--topbarHeightDesktop) + var(--topfilterHeightDesktop));
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}
.searchResultMainContainer{
  width: 100%;
  flex-grow: 1;
  display: flex;  
}
/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 20px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) { 
  }

  @media (--viewportLarge) {
  }
}

.searchFiltersMobileMap {
  padding: 10px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 10px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}
.filterBar{
  display: flex;
  align-items: center;
}
.filterBarRight{
  display: flex;
  align-items: center;
  flex-basis: 37.5%;
  justify-content: space-between;
  padding-left: 15px;
}
.allFilters{
  display: flex;
  align-items: center;
  gap: 10px;
}
.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: calc(var(--topbarHeightDesktop) + var(--topfilterHeightDesktop));
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  /* flex: 1; */
  flex-basis: 430.5%;
  padding-right: 15px;
}

.toggleContainerHideMap {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  /* flex: 1; */
  flex-basis: 62.5%;
}

.toggleSwitchContainer {
  display: flex;
  align-items: center; 
}

.togglelabel {
  margin-right: 10px; 
}

.sortyByWrapper {
  display: inline-flex;
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.resultsFound {
  white-space: nowrap;
}

.mobilitySwitch {  
}

.mobilitySwitchMobileResponse { 
  text-align: center;
  margin-top: 10px;
}